import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { FlexBox, Modal, TextField, theme, trackEvent } from '@rario/shared-components'
import { PickPriceContainer } from 'components/NavigationBar/StickyFloatingButton.styles'
import RarioCoinIcon from 'assets/svg/RarioCoinIcon'
import dynamic from 'next/dynamic'
import { ModalContainer } from 'components/PhoneVerification/PhoneVerification.styles'
import { ModalContext } from './ModalProvider'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { PurchaseSummaryProvider } from 'components/PurchaseSummary/PurchaseSummaryContext'
import { IN_APP_PURCHASE_COOKIE, PHONE_VERIFICATION_SKIP_COOKIE } from 'constant'
import CancelPurchase from 'components/PurchaseSummary/CancelPurchase'
import usePageControl from 'hooks/usePageControl'
import { isUsernameValid } from 'utils/utils'
import { riskProfileStatusEnum } from 'interfaces'
import DisableAddFundsModal from 'components/AddFunds/DisableAddFundsModal'
import RestrictedUserModal from 'components/AddFunds/RestrictedUserModal'

// const EmailVerificationModal = dynamic(import('components/EmailVerificationModal'))
const PhoneVerification = dynamic(import('components/PhoneVerification'))
const PlayerPool = dynamic(import('components/PaymentPage/PlayerPool'))
const PurchaseSummary = dynamic(import('components/PurchaseSummary/PurchaseSummary'))
const UserProfile = dynamic(import('components/PurchaseSummary/UserProfile'))

const ModalManager: FunctionComponent = () => {
  const { user } = useCustomUser()
  const { isHomeLandingPage } = usePageControl()
  const {
    userProfileModal,
    setUserProfileModal,
    phoneVerificationModal,
    setPhoneVerificationModal,
    setShowEmailVerificationModal,
    playerPoolModal,
    setPlayerPoolModal,
    discountModal,
    setDiscountModal,
    infoModal,
    setInfoModal,
    paymentSummaryModal,
    setPaymentSummaryModal,
    cancelPaymentModal,
    setCancelPaymentModal,
    showDisableAddFundsModal,
    showRestrictedUserModal,
    showRestrictedWithdrawalModal,
    // showEmailVerificationModal,
  } = useContext(ModalContext)
  const { isAddFundsPage, isCircleStatusPage } = usePageControl()

  const router = useRouter()
  const orderIdQuery = router?.query?.order_id as string
  const paymentSummaryQuery = router?.query?.modal as string

  const [cookies, , removeCookie] = useCookies([
    PHONE_VERIFICATION_SKIP_COOKIE,
    IN_APP_PURCHASE_COOKIE.ORDER_ID,
    IN_APP_PURCHASE_COOKIE.URL,
  ])

  const [disableOutsideClick, setDisableOutsideClick] = useState<boolean>(false)

  // first and last name update modal trigger on page load
  useEffect(() => {
    if (user?.profile?.firstName && isHomeLandingPage) {
      if (!isUsernameValid(user?.profile?.firstName, user?.profile?.lastName)) {
        setUserProfileModal({ show: false })
      }
    }
  }, [])

  // phone verification modal trigger on page load.
  useEffect(() => {
    if (!user?.isEmailVerified && user?.riskProfileStatus !== riskProfileStatusEnum.BLACKLIST) {
      setShowEmailVerificationModal(true)
    } else if (
      !user?.isPhoneVerified &&
      user?.riskProfileStatus !== riskProfileStatusEnum.BLACKLIST
    ) {
      setPhoneVerificationModal({ canSkip: false })
    }
  }, [user])

  // payment summary modal on page load. use case = redirection from add-funds
  useEffect(() => {
    if (orderIdQuery && paymentSummaryQuery) {
      setPaymentSummaryModal({ orderId: orderIdQuery })
      removeCookie(IN_APP_PURCHASE_COOKIE.ORDER_ID, { path: '/' })
      removeCookie(IN_APP_PURCHASE_COOKIE.URL, { path: '/' })
    }
  }, [orderIdQuery, paymentSummaryQuery])

  // payment summary modal on page load. use case = not having any query params related to payment && still having cookie
  useEffect(() => {
    if (cookies?.[IN_APP_PURCHASE_COOKIE.URL]) {
      if (!isAddFundsPage && !isCircleStatusPage && !orderIdQuery && !paymentSummaryQuery) {
        removeCookie(IN_APP_PURCHASE_COOKIE.ORDER_ID, { path: '/' })
        removeCookie(IN_APP_PURCHASE_COOKIE.URL, { path: '/' })
      }
    }
  }, [router])

  return (
    <>
      {user && phoneVerificationModal && (
        <Modal
          bodyBackground="rgba(255,255,255,0.1)"
          background={'#11151C'}
          visible
          showCloseIcon={false}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          noPadding
        >
          <ModalContainer>
            <PhoneVerification
              canSkip={phoneVerificationModal.canSkip}
              subHeading={phoneVerificationModal.subHeading}
            />
          </ModalContainer>
        </Modal>
      )}

      {/* {user && showEmailVerificationModal && <EmailVerificationModal />} */}

      {/* {showBonusWidgetModal && <BonusWidgetModal />} */}

      {discountModal && (
        <Modal
          bodyBackground={theme.colors.whites[3]}
          background={'#000'}
          visible
          showCloseIcon={false}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          isOutsideClick={true}
          onClick={() => {
            setDiscountModal(undefined)
          }}
        >
          <FlexBox flexDirection={'column'} width={'100%'}>
            <FlexBox
              height={'0px'}
              width={'42px'}
              border={`2px solid ${theme.colors.whites[7]}`}
              borderRadius={'2px'}
              mx={'auto'}
              mt={'20px'}
            />
            <FlexBox flexDirection={'row'} justifyContent={'space-between'} my={'20px'}>
              <FlexBox mt={['3px', null, null, '0px']} flexDirection="column">
                <TextField
                  fontFamily={theme.fonts.primary}
                  letterSpacing={theme.letterSpacings.primary}
                  fontSize={['16px']}
                  lineHeight={['22px']}
                  fontWeight={'600'}
                  ml={'10px'}
                >
                  Get this card for as low as {discountModal.discountedPrice}
                </TextField>
                <TextField
                  fontFamily={theme.fonts.primary}
                  letterSpacing={theme.letterSpacings.primary}
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  fontWeight={'400'}
                  color={theme.colors.whites[8]}
                  ml={'10px'}
                >
                  You can use your Rario Bonus to pay for 50% of your transaction value
                </TextField>
                <PickPriceContainer>
                  <TextField
                    width={'65px'}
                    ml={'13px'}
                    mt={'4px'}
                    fontWeight={'400'}
                    fontSize={'10px'}
                    lineHeight={'14px'}
                  >
                    Rario Bonus Balance
                  </TextField>
                  <FlexBox
                    mt={'5px'}
                    height={'70%'}
                    width={'1px'}
                    background={theme.colors.grey}
                    mr={'5px'}
                  />
                  <FlexBox mx={'5px'} mt={'5px'}>
                    <RarioCoinIcon height="25px" width="25px" fill={theme.colors.cyanBlue} />
                  </FlexBox>
                  <TextField
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'16px'}
                    mr={'10px'}
                    mt={'10px'}
                  >
                    {discountModal.marketingWalletBalance}
                  </TextField>
                </PickPriceContainer>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Modal>
      )}

      {user && (
        <Modal
          visible={!!paymentSummaryModal?.orderId}
          background={'#11151C'}
          onClick={() => {
            if (!disableOutsideClick) {
              setCancelPaymentModal({ show: true })
            }
          }}
          isOutsideClick={false}
          bodyBackground={theme.colors.whites[3]}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          showCloseIcon={true}
          top={'-50px'}
          right={'15px'}
          desktopTop={'-50px'}
          desktopRight={'15px'}
          crossMarkWidth={18}
          crossMarkHeight={18}
          disableCross={disableOutsideClick}
          disableBorderRadius
        >
          <PurchaseSummaryProvider>
            <FlexBox maxHeight={'85vh'} flexDirection={'column'} overflow={'auto'} width={'100%'}>
              <PurchaseSummary
                orderId={paymentSummaryModal?.orderId || ''}
                xp={paymentSummaryModal?.xp}
                disableModalOutsideClick={(toggle: boolean) => setDisableOutsideClick(toggle)}
              />
            </FlexBox>
          </PurchaseSummaryProvider>
        </Modal>
      )}

      {user && userProfileModal.show && (
        <Modal
          background={'#11151C'}
          bodyBackground={theme.colors.whites[3]}
          visible
          isOutsideClick={false}
          showCloseIcon={false}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          top={'13%'}
          maxWidth={784}
          showTopLine
          disableBorderRadius
        >
          <UserProfile />
        </Modal>
      )}

      {infoModal && (
        <Modal
          visible
          modalPosition="flex-end"
          background="#11151C"
          onClick={() => {
            setInfoModal(undefined)
          }}
          isOutsideClick={true}
          variant="fullScreen"
          showTopLine
          showCloseIcon={false}
          noPadding
        >
          <FlexBox px="15px" py="35px" borderRadius="10px 10px 0 0" flexDirection={'column'}>
            <TextField
              fontWeight={600}
              fontSize="20px"
              lineHeight="30px"
              p={0}
              mr="4px"
              mb={'10px'}
            >
              {infoModal.heading}
            </TextField>
            {infoModal.description}
          </FlexBox>
        </Modal>
      )}

      {playerPoolModal && (
        <Modal
          background={'#000'}
          bodyBackground="rgba(255,255,255,0.1)"
          visible
          isOutsideClick={true}
          showCloseIcon={true}
          onClick={() => {
            setPlayerPoolModal(undefined)
            trackEvent('Section Interacted', 'click', {
              sub_category: `${playerPoolModal?.source}-player_pool_sheet_cross_icon`,
              view_type: 'constrained',
              click_type: 'icon',
              category: `${playerPoolModal?.source}`,
            })
          }}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          showTopLine
          noPadding
          // disableBorderRadius
        >
          <FlexBox
            mt={'25px'}
            maxHeight={paymentSummaryModal ? '75vh' : '85vh'}
            overflow={'auto'}
            width={'100%'}
          >
            <PlayerPool
              cmsRefID={playerPoolModal?.cmsRefId as string}
              source={playerPoolModal?.source}
            />
          </FlexBox>
        </Modal>
      )}

      {cancelPaymentModal.show && (
        <Modal
          bodyBackground={theme.colors.whites[3]}
          background={'#000'}
          visible
          showCloseIcon={false}
          modalPosition={'flex-end'}
          variant={'fullScreen'}
          noPadding
          showTopLine
        >
          <CancelPurchase />
        </Modal>
      )}

      {showDisableAddFundsModal && <DisableAddFundsModal />}
      {showRestrictedUserModal && <RestrictedUserModal />}
      {showRestrictedWithdrawalModal && <RestrictedUserModal showContactSupportText />}
    </>
  )
}

export default ModalManager
