import { CancelType } from 'interfaces/purchaseFlow'
import { ReactNode, useMemo, useState, createContext, Dispatch, SetStateAction } from 'react'

type UserProfileModalProps = {
  show: boolean
  isSuccess?: boolean
  pageSource?: string
}

type PhoneVerificationModalProps = {
  canSkip?: boolean
  subHeading?: string
}

type PlayerPoolModalProps = {
  cmsRefId?: string
  source?: string
}

type DiscountModalProps = {
  discountedPrice?: string
  marketingWalletBalance?: string
}

type InfoModalProps = {
  heading?: string
  description?: string
}

type PaymentSummaryModalProps = {
  orderId?: string
  xp?: number
}

type CancelPaymentModalProps = {
  show: boolean
  type?: CancelType
}

export type ModalContextProps = {
  userProfileModal: UserProfileModalProps
  setUserProfileModal: Dispatch<SetStateAction<UserProfileModalProps>>
  phoneVerificationModal?: PhoneVerificationModalProps
  setPhoneVerificationModal: Dispatch<SetStateAction<PhoneVerificationModalProps | undefined>>
  playerPoolModal?: PlayerPoolModalProps
  setPlayerPoolModal: Dispatch<SetStateAction<PlayerPoolModalProps | undefined>>
  discountModal?: DiscountModalProps
  setDiscountModal: Dispatch<SetStateAction<DiscountModalProps | undefined>>
  infoModal?: InfoModalProps
  setInfoModal: Dispatch<SetStateAction<InfoModalProps | undefined>>
  paymentSummaryModal?: PaymentSummaryModalProps
  setPaymentSummaryModal: Dispatch<SetStateAction<PaymentSummaryModalProps | undefined>>
  closeSelectAndBuyModal: boolean
  setCloseSelectAndBuyModal: Dispatch<SetStateAction<boolean>>
  showBonusWidgetModal?: boolean
  setShowBonusWidgetModal: Dispatch<SetStateAction<boolean | undefined>>
  cancelPaymentModal: CancelPaymentModalProps
  setCancelPaymentModal: Dispatch<SetStateAction<CancelPaymentModalProps>>
  showEmailVerificationModal?: boolean
  setShowEmailVerificationModal: Dispatch<SetStateAction<boolean | undefined>>
  showDisableAddFundsModal?: boolean
  setShowDisableAddFundsModal: Dispatch<SetStateAction<boolean | undefined>>
  showRestrictedUserModal?: boolean
  setShowRestrictedUserModal: Dispatch<SetStateAction<boolean | undefined>>
  showRestrictedWithdrawalModal?: boolean
  isRestricted?: boolean
  setShowRestrictedWithdrawalModal: Dispatch<SetStateAction<boolean | undefined>>
}

const defaultUserProfileModalValues = {
  show: false,
}

export const ModalContext = createContext<ModalContextProps>({
  userProfileModal: defaultUserProfileModalValues,
  setUserProfileModal: () => {},
  setPhoneVerificationModal: () => {},
  setPlayerPoolModal: () => {},
  setDiscountModal: () => {},
  setInfoModal: () => {},
  setPaymentSummaryModal: () => {},
  closeSelectAndBuyModal: false,
  setCloseSelectAndBuyModal: () => {},
  setShowBonusWidgetModal: () => {},
  cancelPaymentModal: defaultUserProfileModalValues,
  setCancelPaymentModal: () => {},
  showEmailVerificationModal: false,
  setShowEmailVerificationModal: () => {},
  showDisableAddFundsModal: false,
  setShowDisableAddFundsModal: () => {},
  showRestrictedUserModal: false,
  setShowRestrictedUserModal: () => {},
  showRestrictedWithdrawalModal: false,
  isRestricted: false,
  setShowRestrictedWithdrawalModal: () => {},
})

const ModalProvider = ({
  children,
  isRestricted,
}: {
  children: ReactNode
  isRestricted: boolean
}) => {
  const [userProfileModal, setUserProfileModal] = useState<UserProfileModalProps>(
    defaultUserProfileModalValues
  )
  const [phoneVerificationModal, setPhoneVerificationModal] = useState<
    PhoneVerificationModalProps | undefined
  >(undefined)

  const [playerPoolModal, setPlayerPoolModal] = useState<PlayerPoolModalProps | undefined>(
    undefined
  )

  const [discountModal, setDiscountModal] = useState<DiscountModalProps | undefined>(undefined)

  const [infoModal, setInfoModal] = useState<InfoModalProps | undefined>(undefined)

  const [paymentSummaryModal, setPaymentSummaryModal] = useState<
    PaymentSummaryModalProps | undefined
  >(undefined)
  const [closeSelectAndBuyModal, setCloseSelectAndBuyModal] = useState<boolean>(false)

  const [showBonusWidgetModal, setShowBonusWidgetModal] = useState<boolean>()

  const [cancelPaymentModal, setCancelPaymentModal] = useState<CancelPaymentModalProps>(
    defaultUserProfileModalValues
  )
  const [showDisableAddFundsModal, setShowDisableAddFundsModal] = useState<boolean | undefined>(
    false
  )
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState<boolean | undefined>(
    false
  )
  const [showRestrictedUserModal, setShowRestrictedUserModal] = useState<boolean | undefined>(false)
  const [showRestrictedWithdrawalModal, setShowRestrictedWithdrawalModal] = useState<
    boolean | undefined
  >(false)

  const value = useMemo(
    () => ({
      userProfileModal,
      setUserProfileModal,
      phoneVerificationModal,
      setPhoneVerificationModal,
      playerPoolModal,
      setPlayerPoolModal,
      discountModal,
      setDiscountModal,
      infoModal,
      setInfoModal,
      paymentSummaryModal,
      setPaymentSummaryModal,
      closeSelectAndBuyModal,
      setCloseSelectAndBuyModal,
      showBonusWidgetModal,
      setShowBonusWidgetModal,
      cancelPaymentModal,
      setCancelPaymentModal,
      showEmailVerificationModal,
      setShowEmailVerificationModal,
      showDisableAddFundsModal,
      setShowDisableAddFundsModal,
      showRestrictedUserModal,
      setShowRestrictedUserModal,
      showRestrictedWithdrawalModal,
      setShowRestrictedWithdrawalModal,
      isRestricted,
    }),
    [
      userProfileModal,
      setUserProfileModal,
      phoneVerificationModal,
      setPhoneVerificationModal,
      playerPoolModal,
      setPlayerPoolModal,
      discountModal,
      setDiscountModal,
      infoModal,
      setInfoModal,
      paymentSummaryModal,
      setPaymentSummaryModal,
      closeSelectAndBuyModal,
      setCloseSelectAndBuyModal,
      showBonusWidgetModal,
      setShowBonusWidgetModal,
      cancelPaymentModal,
      setCancelPaymentModal,
      showEmailVerificationModal,
      setShowEmailVerificationModal,
      showDisableAddFundsModal,
      showRestrictedUserModal,
      setShowRestrictedUserModal,
      showRestrictedWithdrawalModal,
      setShowRestrictedWithdrawalModal,
      isRestricted,
    ]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalProvider
