// You can include shared interfaces/types in a separate file
// and then use them in any component by importing them. For
// example, to import the interface below do:
//

import { SearchState, Hit } from 'react-instantsearch-core'
import { FLexBoxProps } from '@rario/shared-components'
import React, {
  Dispatch,
  ChangeEventHandler,
  ReactElement,
  SetStateAction,
  CSSProperties,
} from 'react'
import { ImageProps } from 'next/image'
import { Description } from './cmsContentTypes'
import { ActivityDetailProps } from './wallet'
import { WithdrawalOptionsInput } from 'interfaces/withdrawFunds'
import { Document } from '@contentful/rich-text-types'
import { CMSPages } from './leagues'
import { AvailableCurrency } from './currency'
import { CampaignData } from './burnEarn'

export type StylePropertyValue = number | string | number[] | string[] | (string | null)[]

export enum riskProfileStatusEnum {
  // eslint-disable-next-line no-unused-vars
  BLACKLIST = 'blacklist',
  // eslint-disable-next-line no-unused-vars
  WHITELIST = 'whitelist',
}

export interface NextJsImageProps {
  src: string
  alt: string
  width: number
  height: number
  styles?: CSSProperties | undefined
  layout?: ImageProps['layout']
  objectFit?: ImageProps['objectFit'] | undefined
  isAbsoluteUrl?: boolean
}
export interface MetaDataProps {
  registrationSource: string
  registrationMedium?: string
  hasPurchased: boolean
  isKycRequired?: boolean
  innerCircleIntroVideoUrl?: string
  cohorts?: Array<UserCohorts>
}

export interface SocialProfileProps {
  instagram?: string
  twitter?: string
  facebook?: string
}

export interface UserProfileProps {
  email: string
  name?: string | null
  nickname?: string | null
  picture?: string | null
  // sub: string
  updated_at?: string | null
  // org_id?: string | null
  // family_name?: string | null
  // given_name?: string | null
  username: string
  isPhoneVerified?: boolean
  phoneIsoCode?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  auth0ConnectionName?: string
  kycStatus?: string
  isAccountDeactivated?: boolean
  kycVerificationError?: string
  billingDetails?: UserBillingDetailsProps
  profile?: UserKycProfileProps
  riskProfileStatus?: riskProfileStatusEnum.BLACKLIST | riskProfileStatusEnum.WHITELIST
  isEmailVerified?: boolean
  isUsernameUpdated?: boolean
  referralUrl?: string
  referralCode?: string
  last_login?: string
  maskedPan?: string
  // auth0Id?: string
  userId?: string
  createdAt?: number
  metadata: MetaDataProps
  socialProfile?: SocialProfileProps
  isTrialEnabled?: boolean
  hasPlayedTrialOnce?: boolean
  userCohortName?: string
}

export interface UserBillingDetailsProps {
  country: string
  firstName: string
  lastName: string
  city: string
  district: string
  postalCode: string
  line1: string
}

export enum UserCohorts {
  // eslint-disable-next-line no-unused-vars
  C1 = 'C1',
  // eslint-disable-next-line no-unused-vars
  C2 = 'C2',
  // eslint-disable-next-line no-unused-vars
  C3 = 'C3',
}

export interface UserKycProfileProps {
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  issuingCountry?: string
  maskedPan?: string
}

export interface MobileRoutes {
  title: string
  menu: RouteChildren[]
}
export interface MobileRouteProps {
  id: number
  name: string
  link?: string
  selected?: boolean
  flag?: string
  inactive?: boolean
  flagBg?: string
  submenu?: boolean
  newTab: boolean
  child?: RouteChildren[]
  compliance?: boolean
  cr?: boolean
}
export interface RouteChildren {
  icon?: React.ReactElement
  name?: string
  subtitle?: string
  link?: string
  selected?: boolean
  flag?: string
  flagColor?: string
  newTab?: boolean
  subMenu?: boolean
  rightIcon?: React.ReactElement
  child?: RouteChildren[]
  className?: string
  cr?: boolean
}

export interface SidenavBottomType {
  name: string
  logo: string
  width: string
  height: string
}

export type PackTypeProps = {
  title: String
  packsData: PacksDoc[]
  currentFacetValue?: string | undefined | string[]
}

export type PromotionBanner = {
  title: string
  textContent: string
  isEnabled: boolean
  image: {
    fields: {
      file: { url: string }
    }
  }
  cta: {
    fields: {
      text: string
      link: string
      openInNewTab: boolean
    }
  }
}

export type RecentSalesHistoryProps = {
  catalogDoc: NFTCatalogDoc
  tokenDoc?: NFTTokenDoc
}

export enum MediaTypeEnum {
  // eslint-disable-next-line no-unused-vars
  IMAGE = 'image',
  // eslint-disable-next-line no-unused-vars
  VIDEO = 'video',
}

type MediaType = MediaTypeEnum.IMAGE | MediaTypeEnum.VIDEO

export type MediaTypeProps = {
  name: string
  src: string
  mediaType: MediaType
  poster?: string
}

export type MediaListProps = {
  media: MediaTypeProps[]
  renderImageComponentCallback?: (imageUrl: string) => React.ReactElement
  showManualControls?: boolean
  showMediaListThumbnails?: boolean
  tagStatus?: string
  tagFrom?: string
  scarcity?: string
  cardTag?: string
  isStarPlayer?: boolean
  showSoldByPlatform?: boolean
  isPlayerCard?: boolean
  isTrialCard?: boolean
  trialCardExpiresIn?: number
}

export type NFTTagsProps = {
  source: string
  campaignName?: string
  tour: string
  team1: string
  team2: string
  contests: string[]
  roundStartTime: string
}

export enum TabEnum {
  // eslint-disable-next-line no-unused-vars
  PACKS = 'packs',
  // eslint-disable-next-line no-unused-vars
  CARDS = 'cards',
  // eslint-disable-next-line no-unused-vars
  MOMENTS = 'moments',
  // eslint-disable-next-line no-unused-vars
  LATEST = 'latest',
  // eslint-disable-next-line no-unused-vars
  PREVIOUS = 'previous',
  // eslint-disable-next-line no-unused-vars
  FAVOURITES = 'favourites',
  // eslint-disable-next-line no-unused-vars
  PICKS = 'picks',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'SUCCESS',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  FAN_BADGE = 'fan-badge',
}

// eslint-disable-next-line no-unused-vars
enum ScarcityEnum {
  // eslint-disable-next-line no-unused-vars
  GOLD = 'gold',
  // eslint-disable-next-line no-unused-vars
  SILVER = 'silver',
  // eslint-disable-next-line no-unused-vars
  BRONZE = 'bronze',
  // eslint-disable-next-line no-unused-vars
  UNIQUE = 'unique',
  // eslint-disable-next-line no-unused-vars
  BLACK = 'black',
}

export enum FavouritesEnums {
  // eslint-disable-next-line no-unused-vars
  LEAGUES = 'leagues',
  // eslint-disable-next-line no-unused-vars
  TEAMS = 'teams',
  // eslint-disable-next-line no-unused-vars
  PLAYERS = 'players',
  // eslint-disable-next-line no-unused-vars
  BOARDS = 'boards',
}

// export enum FavouriteTeamEnums {
//   // eslint-disable-next-line no-unused-vars
//   Leagues = 'All Leagues',
//   // eslint-disable-next-line no-unused-vars
//   CPL = 'CPL',
//   // eslint-disable-next-line no-unused-vars
//   WBL = 'WBL',
//   // eslint-disable-next-line no-unused-vars
//   BBL = 'BBL',
//   // eslint-disable-next-line no-unused-vars
//   ADT10 = 'ADT10',
//   // eslint-disable-next-line no-unused-vars
//   LLC = 'LLC',
//   // eslint-disable-next-line no-unused-vars
//   LPL_T20 = 'LPL T20',
// }

export type CARD_TYPES =
  | ScarcityEnum.GOLD
  | ScarcityEnum.SILVER
  | ScarcityEnum.BRONZE
  | ScarcityEnum.UNIQUE
  | ScarcityEnum.BLACK

export enum CatalogTypeEnum {
  // eslint-disable-next-line no-unused-vars
  MOMENT = 'moment',
  // eslint-disable-next-line no-unused-vars
  CARD = 'card',
  // eslint-disable-next-line no-unused-vars
  PACK = 'pack',
  // eslint-disable-next-line no-unused-vars
  MARKET_PLACE = 'marketplace',
  // eslint-disable-next-line no-unused-vars
  PICKS = 'picks',
  // eslint-disable-next-line no-unused-vars
  FAN_BADGE = 'fan_badge',
  // eslint-disable-next-line no-unused-vars
  EXCHANGE = 'exchange',
}

export type CatalogType =
  | CatalogTypeEnum.MOMENT
  | CatalogTypeEnum.CARD
  | CatalogTypeEnum.PACK
  | CatalogTypeEnum.MARKET_PLACE
  | CatalogTypeEnum.PICKS
  | CatalogTypeEnum.FAN_BADGE
  | CatalogTypeEnum.EXCHANGE

type NFTAttributes = {
  batting_style?: string
  bowling_style?: string
  league: string
  nationality?: string
  player: string
  role: string
  scarcity: CARD_TYPES
  season: string
  // serial_number: string
  team: string
  opponent_team?: string
  match_format?: string
  tournament: string
  year: string
  team_name?: string
}

interface NFTBasicDoc {
  objectID: string
  name: string
  // catalog_id: string
  catalog_type: CatalogType
  attributes: NFTAttributes
  price_currency: string
  image: string
  total_serial_number: string
  media: MediaTypeProps[]
  description_text: string
  additional_description_text?: string
  expert_video?: {
    id: string
    type: MediaType
    src: string
  }
}

export interface associatedLeagueTeamListProps {
  leagueName?: string
  leagueShortName?: string
  teamName?: string
  teamShortName?: string
}

export interface NFTCatalogDoc extends NFTBasicDoc {
  on_sale?: boolean
  min_sale_price?: number
  max_sale_price?: number
  listing_count?: number
  num_of_usage?: number
  unique_seller_count?: number
  min_serial_number?: number
  max_serial_number?: number
  tags?: NFTTagsProps
  moment_type?: string
  team?: string
  match_date?: string
  asset_id?: string
  sub_type?: string
  batting_position?: number
  bowling_position?: number
  is_star_player?: boolean
  is_pick?: boolean
  sale_price?: number
  campaign_end_ts?: number
  campaign_start_ts?: number
  listing?: {
    listing_id: string
    listing_price: string
    listing_price_currency: string
    status: string
    listing_time?: number
  }
  listingsInfo?: {
    catalog_id: string
    listing_count: number
    unique_seller_count: number
    min_serial_number: string
    max_serial_number: string
    min_sale_price: string
    max_sale_price: string
  }
  associated_leagues?: []
  associated_teams?: []
  associated_league_team_list?: associatedLeagueTeamListProps[]
}

export enum TRANSACTION_STATUS {
  // eslint-disable-next-line no-unused-vars
  TRANSFER_COMPLETED = 'transfer_completed',
  // eslint-disable-next-line no-unused-vars
  MINT_TRANSFER_INITIATED = 'mint_transfer_initiated',
  // eslint-disable-next-line no-unused-vars
  MINT_TRANSFER_COMPLETED = 'mint_transfer_completed',
  // eslint-disable-next-line no-unused-vars
  REVEAL_PENDING = 'reveal_pending',
  // eslint-disable-next-line no-unused-vars
  REVEAL_COMPLETED = 'reveal_completed',
  // eslint-disable-next-line no-unused-vars
  MINT_TRANSFER_RESUBMIT = 'mint_transfer_resubmit',
  // eslint-disable-next-line no-unused-vars
  LISTED_FOR_SALE = 'listed_for_sale',
  // eslint-disable-next-line no-unused-vars
  CREATED = 'created',
  // eslint-disable-next-line no-unused-vars
  PURCHASED = 'purchased',
  // eslint-disable-next-line no-unused-vars
  PUBLISHED_FOR_SALE = 'published_for_sale',
  // eslint-disable-next-line no-unused-vars
  MINT_TRANSFER_RESUBMITTED = 'mint_transfer_resubmitted',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_INITIATED = 'purchase_initiated',
  // eslint-disable-next-line no-unused-vars
  WALLET_PAYMENT_INITIATED = 'wallet_payment_initiated',
  // eslint-disable-next-line no-unused-vars
  WALLET_PAYMENT_COMPLETED = 'wallet_payment_completed',
  // eslint-disable-next-line no-unused-vars
  WALLET_PAYMENT_FAILED = 'wallet_payment_failed',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_COMPLETED = 'purchase_completed',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_CANCELED = 'purchase_canceled',
  // eslint-disable-next-line no-unused-vars
  TRANSFER_INITIATED = 'transfer_initiated',
  // eslint-disable-next-line no-unused-vars
  LISTING_INITIATED = 'listing_initiated',
  // eslint-disable-next-line no-unused-vars
  LISTING_CANCELED = 'listing_canceled',
  // eslint-disable-next-line no-unused-vars
  BURN_INITIATED = 'burn_initiated',
  // eslint-disable-next-line no-unused-vars
  BURN_COMPLETED = 'burn_completed',
  // eslint-disable-next-line no-unused-vars
  LISTING_CANCEL_INITIATED = 'listing_cancel_initiated',
  // eslint-disable-next-line no-unused-vars
  TRANSFER_RESUBMITTED = 'transfer_resubmitted',
  // eslint-disable-next-line no-unused-vars
  LISTING_UPDATE_INITIATED = 'listing_update_initiated',
}

export type TokenTransactionStatus =
  | TRANSACTION_STATUS.TRANSFER_COMPLETED
  | TRANSACTION_STATUS.MINT_TRANSFER_INITIATED
  | TRANSACTION_STATUS.MINT_TRANSFER_COMPLETED
  | TRANSACTION_STATUS.REVEAL_PENDING
  | TRANSACTION_STATUS.REVEAL_COMPLETED
  | TRANSACTION_STATUS.MINT_TRANSFER_RESUBMIT
  | TRANSACTION_STATUS.LISTED_FOR_SALE
  | TRANSACTION_STATUS.CREATED
  | TRANSACTION_STATUS.PURCHASED
  | TRANSACTION_STATUS.PUBLISHED_FOR_SALE
  | TRANSACTION_STATUS.MINT_TRANSFER_RESUBMITTED
  | TRANSACTION_STATUS.PURCHASE_INITIATED
  | TRANSACTION_STATUS.WALLET_PAYMENT_INITIATED
  | TRANSACTION_STATUS.WALLET_PAYMENT_COMPLETED
  | TRANSACTION_STATUS.WALLET_PAYMENT_FAILED
  | TRANSACTION_STATUS.PURCHASE_COMPLETED
  | TRANSACTION_STATUS.PURCHASE_CANCELED
  | TRANSACTION_STATUS.TRANSFER_INITIATED
  | TRANSACTION_STATUS.LISTING_INITIATED
  | TRANSACTION_STATUS.LISTING_CANCELED
  | TRANSACTION_STATUS.BURN_INITIATED
  | TRANSACTION_STATUS.BURN_COMPLETED
  | TRANSACTION_STATUS.LISTING_CANCEL_INITIATED
  | TRANSACTION_STATUS.TRANSFER_RESUBMITTED
  | TRANSACTION_STATUS.LISTING_UPDATE_INITIATED

export enum LISTING_STATUS {
  // eslint-disable-next-line no-unused-vars
  PURCHASE_INITIATED = 'purchase_initiated',
  // eslint-disable-next-line no-unused-vars
  TRANSFER_COMPLETED = 'transfer_completed',
  // eslint-disable-next-line no-unused-vars
  LISTED_FOR_SALE = 'listed_for_sale',
  // eslint-disable-next-line no-unused-vars
  LISTING_INITIATED = 'listing_initiated',
  // eslint-disable-next-line no-unused-vars
  LISTING_CANCELED = 'listing_canceled',
  // eslint-disable-next-line no-unused-vars
  LISTING_CANCEL_INITIATED = 'listing_cancel_initiated',
  // eslint-disable-next-line no-unused-vars
  LISTING_UPDATE_INITIATED = 'listing_update_initiated',
}

export interface NFTTokenDoc extends NFTCatalogDoc {
  // nft_id: string // TODO: remove nft_id as objectID has same value
  username?: string
  user_id?: string
  catalog_id: string
  asset_id?: string
  objectID: string
  sale_price: number // TODO: rename with listing_price
  last_sale_price?: number
  serial_number?: number
  player_id?: string
  mint_transfered_at?: string
  purchased_at: string
  blockchain_token_id: string
  block_explorer_url?: string
  token_contract_hash?: string
  on_sale?: boolean
  status: TokenTransactionStatus
  is_pick?: boolean
  priority?: number
  tokenId?: string
  is_trial_card?: boolean
  expires_at?: number
  listing?: {
    listing_id: string
    listing_price: string
    listing_price_currency: string
    status: string
    listing_time?: number
  }
  listingsInfo?: {
    catalog_id: string
    listing_count: number
    unique_seller_count: number
    min_serial_number: string
    max_serial_number: string
    min_sale_price: string
    max_sale_price: string
  }
}
export interface ProfilePageProps {
  searchState: SearchState
}

export interface ProfileDetaisEditProps {
  firstName?: string
  lastName?: string
  userName?: string
}

export interface VaultPacksDoc {
  image_url: string
  name: string
  moment_type: string
  listingId: string
  orderId: Number
  objectID: Number
  catalog_id: Number
  reveal_time: String
  purchase_date: String
  price: number
}
export interface VaultPackItemProps {
  vaultPacksDoc: VaultPacksDoc
}

export interface VaultNoDataProps {
  title: string
  subTitle: string
  icon: ReactElement
  showButton: boolean
  onBuyPackNowClick?: Function
}

export interface IFormInput {
  firstName: string
  lastName: string
  email: string
  phone: string
  subject: string
  message: string
}

export interface ProfileDetaisEditProps {
  firstName?: string
  lastName?: string
  userName?: string
}
export interface JoinSocialsProps {
  title: string
  titleAlign?: string
  packName?: string
}

export interface ShowShareButtonProps {
  showShareButtons?: boolean
  text?: string
  isUserProfilePage?: boolean
  ShareWrapperProps?: FLexBoxProps
  shareWidgetProps?: FLexBoxProps
  isReferEarn?: boolean
  referralUrl?: string
  isPick?: boolean
}
export interface NewsSectionProps {
  title: string
}
export interface NewPressData {
  newsPressData: {
    id: number
    title: string
    link: string
    src: string
    image: string
  }[]
}

type PackDescription = { texts: string[]; title: string }

type PackMedia = {
  src: string
  mediaType: MediaType
  name: string
}

export interface PackAttributes {
  league: string
  scarcity: string
  season?: string
  tournament?: string
  year?: number
  team?: string[]
}

export interface PacksDoc {
  objectID: string
  name?: string
  sale_price: number
  catalog_type?: string
  drop_start_time?: string
  list_of_moments_cms_ref_id?: string
  description_text?: string
  is_sold_out: boolean
  item_count: number
  sold_quantity: number
  total_quantity: number
  asset_id: string
  image: string
  media: PackMedia[]
  reservation_start_time: string
  pack_summary: string[]
  description_section2_image?: string
  description_section1_image?: string
  description_section1?: PackDescription
  description_section2?: PackDescription
  reservation_start_ts: number
  reservation_end_ts: number
  drop_start_ts: number
  drop_end_ts: number
  show_progress_bar: boolean
  card_type: string
  max_qty_allowed_per_order: number
  max_qty_allowed_per_user: number
  status: string
  pack_config: packConfig
  attributes?: PackAttributes
  player_pool_count?: number
  is_starter_pack?: boolean
  user_cohorts?: Array<UserCohorts>
}

export interface packConfig {
  black: number
  gold: number
  silver: number
  bronze: number
  blue: number
}

export type PicksListingProps = {
  hits?: Hit<NFTTokenDoc>[]
  searchState?: SearchState
  tokenDoc: NFTTokenDoc
}

type FacetType = {
  name: string
  attribute: string
}
export type QuickFiltersType = {
  label: string
  count: number
  isRefined: boolean
  value: FacetType
}
export type FacetsType = FacetType[]

export type SortType = {
  label: string
  value: string
}
export type SortConfigType = {
  default: string
  available_sorting: SortType[]
}

export interface VaultListProps {
  hits: Hit<NFTTokenDoc>[]
  activeIndexTitle: string
  isFacetVisible?: boolean
}

export interface SearchListProps<T> {
  hits: Hit<T>[]
  activeIndexTitle: string
}

export interface SearchComponentProps<T> {
  onSearchStateChange: (searchState: SearchState) => void
  sorting?: SortConfigType
  defaultHitsPerPage: number
  defaultAutoCompleteHitsPerPage?: number
  defaultFilters: string
  defaultAutoCompleteFilters: string
  facets?: FacetsType
  emptyResultMessageTitle: string
  emptyResultMessageSubtitle: string
  activeIndexTitle: string
  SearchPlaceholder?: string
  ListComponent: React.FunctionComponent<SearchListProps<T>>
  quickFilters?: FacetsType
  onHitsCallback?: any
  EmptyResultWidget?: ReactElement
}

export interface AutoCompleteProps {
  onChange: (searchState: SearchState) => void
  defaultFilters: string
  defaultHitsPerPage: number
  indexName: string
  expandSearch: boolean
  onExpandSearchChange: (val: boolean) => void
  SearchPlaceholder: string
  activeIndexTitle: string
}

export interface VerticalSlideItemProps {
  mobileText: string
  desktopText: string
  highlightedWords: string[]
}

export interface ProgressBarProps {
  soldCount: number
  totalCount: number
}
interface OwnTheGameDataProps {
  img: string
  name: string
  index: number
  imageTextData: string
  imageName: string
  nftVideo: string
  safariVideo: string
}

export interface OwnTheGamePlayerProps {
  data: OwnTheGameDataProps
}

export enum NavigationTypeEnum {
  // eslint-disable-next-line no-unused-vars
  PACKS = 'packs',
  // eslint-disable-next-line no-unused-vars
  MARKETPLACE = 'marketplace',
  // eslint-disable-next-line no-unused-vars
}

export interface PreferencesDataType {
  name: string
  value: PreferencesValuesType[] | any
}
export interface PreferencesValuesType {
  image?: string
  description: string
  name?: string
  subDesc?: string
  flag?: boolean
}

export interface CheckoutTimerProps {
  seconds: number
  onTimeEnd: Dispatch<SetStateAction<boolean>>
}

type packsThemeType = {
  backgroundColor: string
  color: string
}

export type TabThemeProps = {
  selected_background: string
  selected_borderColor: string
}

export enum PaymentMethodEnum {
  // eslint-disable-next-line no-unused-vars
  WALLET = 'wallet',
  // eslint-disable-next-line no-unused-vars
  CHECKOUT = 'checkout',
  // eslint-disable-next-line no-unused-vars
  CIRCLE = 'circle',
  // eslint-disable-next-line no-unused-vars
  INAI = 'inai',
  // eslint-disable-next-line no-unused-vars
  INAI_DECENTRO = 'inai-decentro',
  // eslint-disable-next-line no-unused-vars
  INAI_CHECKOUT = 'inai-checkout',
  // eslint-disable-next-line no-unused-vars
  CASHFREE = 'cashfree',
}
export type PaymentMethod = PaymentMethodEnum

export enum OrderStateEnum {
  // eslint-disable-next-line no-unused-vars
  PURCHASE_INITIATED = 'purchase_initiated',
  // eslint-disable-next-line no-unused-vars
  WALLET_PAYMENT_INITIATED = 'wallet_payment_initiated',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_COMPLETED = 'purchase_completed',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_CANCELED = 'purchase_canceled',
}

// eslint-disable-next-line no-unused-vars
export enum PaymentStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  FAIL = 'fail',
  // eslint-disable-next-line no-unused-vars
  SOLDOUT = 'soldOut',
}

// eslint-disable-next-line no-unused-vars
export enum PaymentGatewayStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  PAID = 'paid',
  // eslint-disable-next-line no-unused-vars
  CONFIRMED = 'confirmed',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
  // eslint-disable-next-line no-unused-vars
  ACTION_REQUIRED = 'action_required',
}

// export enum OrderItemStateEnum {
//   // eslint-disable-next-line no-unused-vars
//   REVEAL_PENDING = 'reveal_pending',
//   // eslint-disable-next-line no-unused-vars
//   REVEAL_COMPLETED = 'reveal_completed',
//   // eslint-disable-next-line no-unused-vars
//   MINT_TRANSFER_INITIATED = 'mint_transfer_initiated',
//   // eslint-disable-next-line no-unused-vars
//   MINT_TRANSFER_COMPLETED = 'mint_transfer_completed',
//   // eslint-disable-next-line no-unused-vars
//   TRANSFER_INITIATED = 'transfer_initiated',
//   // eslint-disable-next-line no-unused-vars
//   TRANSFER_COMPLETED = 'transfer_completed',
//   // eslint-disable-next-line no-unused-vars
//   MINT_TRANSFER_RESUBMIT = 'mint_transfer_resubmit',
// }

export type OrderState =
  | OrderStateEnum.PURCHASE_INITIATED
  | OrderStateEnum.WALLET_PAYMENT_INITIATED
  | OrderStateEnum.PURCHASE_COMPLETED
  | OrderStateEnum.PURCHASE_CANCELED

export enum TransactionStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_INITIATED = 'purchase_initiated',
  // eslint-disable-next-line no-unused-vars
  WALLET_PAYMENT_INITIATED = 'wallet_payment_initiated',
  // eslint-disable-next-line no-unused-vars
  ACTION_REQUIRED = 'action_required',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  CONFIRMED = 'confirmed',
  // eslint-disable-next-line no-unused-vars
  PAID = 'paid',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
}

export enum PayoutStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PROCESSSING = 'initiated',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  REQUESTED = 'requested',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
}

export const payoutStatusMapping = new Map<string, string>([
  ['complete', PayoutStatusEnum.SUCCESS],
  ['manual_complete', PayoutStatusEnum.SUCCESS],
  ['failed', PayoutStatusEnum.FAILED],
  ['pending', PayoutStatusEnum.PROCESSSING],
  ['requested', PayoutStatusEnum.PROCESSSING],
  ['manual_requested', PayoutStatusEnum.PROCESSSING],
  ['manual_export_ready', PayoutStatusEnum.PROCESSSING],
  ['manual_transfer_pending', PayoutStatusEnum.PROCESSSING],
  ['manual_transfer_batch_pending', PayoutStatusEnum.PROCESSSING],
])

type OrderItemMetadata = {
  player: {
    name: string
    imageUrl: string
    role: string
  }
  teamInfo: {
    name: string
    imageUrl: string | null
    shortName: string
  }
  opponentTeamInfo: {
    name: string
    imageUrl: string | null
    shortName: string
  }
  match: {
    eventDate: string
  }
  league: {
    name: string
    imageUrl: string | null
    shortName: string
  }
  tournament: {
    name: string
    imageUrl: string | null
    season: string
    year: number
  }
}
type OrderBlockchainTransaction = {
  blockchain: string
  blockchainURL: string
  txnHash: string
  txnType: string
  method: string
  status: string
  from: string
  to: string
  txnFee: number
}

export type OrderItem = {
  id: string
  state: TRANSACTION_STATUS
  serialNumber: string
  totalSerialNumber: string
  scarcity: CARD_TYPES
  name: string
  description: string
  imageUrl: string
  parentImageUrl: string
  backImageUrl: string
  videoUrl: string
  expertVideoUrl: string
  tokenId: string
  mintTransferedAt: string
  playerId: number
  catalogType: CatalogType
  catalogId: string
  price: number
  currency: string
  revealTime: string
  revealedAt: string | null
  blockchainTransaction?: OrderBlockchainTransaction
  metadata: OrderItemMetadata
  tokenListingId: string
  blockchainListingId: string | null
  is_pick?: boolean
}

export type SalesOrderResult = {
  orderId: string
  catalogId: string
  state: OrderState
  purchasedAt: string
  catalogName: string
  orderItems: OrderItem[]
  revealAnimationVideoMovUrl: string
  revealAnimationVideoWebmUrl: string
}

export type BaseSalesOrderGroupResult = {
  amount: number
  catalogName: string
  catalogId: string
  catalogImageUrl: string
  dropDate: string
  catalogItemCount: number
  cartPaymentSummary?: CartPaymentSummaryProps[]
}

export type SalesOrderGroupResult = BaseSalesOrderGroupResult & {
  orderGroupId: string
  state: OrderState
  paymentGateway: PaymentMethod
  orders: SalesOrderResult[]
  currency: string
  saleType: CatalogType
  catalogCardType: string
  orderPayment: OrderPaymentProps
  marketingWallet: MarketingWalletProps
  burnRequestNumber?: string
}

export interface OrderPaymentProps {
  amount: number
  bidAmount: number
  bidCurrency: string
  buyerPayableAmount: number
  currency: string
  discount: number
  paymentMethod?: string
  serviceFee: number
  serviceFeeRate: number
  txnFee: number
  txnFeeRate: number
  updatedAt: string
  paymentTransactionId?: string
}

export interface MarketingWalletProps {
  marketingWalletBalance: number
  redeemableRarioBonusAmount: number
}

export type TransactionStatusResult = {
  paymentId: string
  amount: number
  status: string
  currency: string
  createdAt: number
  fundingType: string
  last4: number
  feeAmount: number
  redirectUrl?: string
}

export type PayoutStatusResult = {
  id: string
  description: string
  amount: number
  serviceFees: number
  currency: string
  status: string
  createdAt: number
  transactionFees: number
  total: number
}

export type OrderPaymentSessionResult = {
  redirectLink: string
}

export interface SellOnMarketModalProps {
  onChangeListingStatus: Function
  catalogDoc: NFTCatalogDoc
  tokenDoc: NFTTokenDoc
  sellModalToggle: Function
  isUpdate?: boolean
  isLoading: boolean
  setIsLoading: Function
  setDisableOutSideClick: Function
  disableOutSideClick: boolean
  listingPrice: string
}

export interface PackSummaryProps {
  packSummary: string[]
}
export interface PaymentStatusButtons {
  isSuccess: boolean
  isFailed: boolean
  isPending?: boolean
  isSoldOut?: boolean
  isCard?: boolean
  isPicks?: boolean
  catalogId: string
  ordersLength?: number
  orderId?: string
  contestRedirectUrl?: string
}
export interface NFTSaleListingProps {
  username: string
  serialNumber: number
  userId: string
  price: number
  itemRowBg?: 'dark' | 'light'
  selectedItem?: number
  listingId: number
  scarcity?: string
  xp?: number | undefined
  nftTagStatus?: string
  index?: number
}

export interface ToggleSwitchInterface {
  text?: string
  checked?: boolean
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export interface FundAmountInput {
  amountInput?: number
}

export interface BillingDetailsInput {
  firstNameInput?: string
  lastNameInput?: string
  addressInput?: string
  stateInput?: string
  cityInput?: string
  postalCodeInput?: string
  countryInput?: string
  countryInputISOCode?: string
}

export interface AddFundsBillingDetailsInput extends BillingDetailsInput, FundAmountInput {
  isSaveAddressChecked: boolean
}

export interface PaymentOptionsInput {
  cardNum?: string
  expDate?: string
  code?: string
  nameOnCard?: string
}

export type PaymentInputKeysType =
  | 'cardNum'
  | 'expDate'
  | 'code'
  | 'nameOnCard'
  | readonly ('cardNum' | 'expDate' | 'code' | 'nameOnCard')[]
  | ('cardNum' | 'expDate' | 'code' | 'nameOnCard')[]
  | undefined

export interface TransactionButtonProps {
  buttonSummaryHtml?: () => React.ReactNode
  buttonText?: string
  onSubmit: () => void
  loader: boolean
  isSuccess: boolean
  disabled: boolean
  fullView?: boolean
  setIsFullView?: Function
  showSwipeButton?: boolean
  isFromAddFunds?: boolean
  showInfoText?: boolean
  buttonContent?: (disabled: boolean) => React.ReactNode
}

export interface WithDrawFundsTransactionSummaryProps {
  amount: number
  totalAmount: number
  serviceFee: number
  isWithdraw?: boolean
  isNewBankAccount: boolean
  loader: boolean
  exchangePrice?: number
  addBankAccountInputs: WithdrawalOptionsInput
  activeTab: string
  isDisabled?: boolean
  onSubmit: (step?: string) => void
  withdrawableBalance: number
}

interface CartPaymentSummaryProps {
  key: string
  amount: number
  type?: string
  label: string
  rate: string
}

// export enum CartPaymentSummaryKeyEnum {
//   CART_VALUE = 'cart_value',
//   RARIO_BONUS = 'rario_bonus',
//   SERVICE_FEE = 'service_fee',
//   TXN_FEE = 'txn_fee',
//   TOTAL = 'total',
// }

// export enum CartPaymentSummaryTypeEnum {
//   DISCOUNT = 'discount',
//   FEE = 'fee',
// }

export type SaveCardDetailsData = {
  expMonth: number
  expYear: number
  encryptedData: string
  billingDetails: UserBillingDetailsProps
  saveAddress?: boolean
}

interface BankDetails {
  bankName: string
  city: string
  country: string
  countryCodeAlpha3?: string
}
export interface BankBillingDetails {
  country: string
  city: string
  district: string
  postalCode: string
  line1: string
}

export type SaveBankAccountData = {
  billingDetails: BankBillingDetails
  bankAddress: BankDetails
  accountHolderName: string
  accountNumber: string
  routingNumber?: string
  ifsc: string | undefined
}

export type BankDetailsResponseData = {
  id: string
  status: string
  routingNumber?: string
  ifsc: string | undefined
  bankAddress: BankDetails
  description: string
  isCoolingPeriodOver: boolean | undefined
}

export type PayoutCallData = {
  amount: number
  currency: string
  bankAccountId: string
}

export type CreatePaymentApiData = {
  amount: number
  sourceCardId: string
  encryptedData: string
}

export type D3XPAPIParam = {
  tokenId: string
  playerId: string
  scarcity: string
  mintTime: number
}

export type D3XPData = {
  tokenId: string
  xp: number
}

export type D3XPDataMap = {
  [key: string]: number
}

// TODO:APP need this ???
export enum D3CatBoostEnum {
  // eslint-disable-next-line no-unused-vars
  BLUE = 'BLUE',
  // eslint-disable-next-line no-unused-vars
  BRONZE = 'BRONZE',
  // eslint-disable-next-line no-unused-vars
  SILVER = 'SILVER',
  // eslint-disable-next-line no-unused-vars
  GOLD = 'GOLD',
  // eslint-disable-next-line no-unused-vars
  BLACK = 'BLACK',
}

export enum D3TagStatusFrom {
  // eslint-disable-next-line no-unused-vars
  VAULT = 'vault',
  // eslint-disable-next-line no-unused-vars
  VAULT_LIST = 'vault_list',
  // eslint-disable-next-line no-unused-vars
  MARKETPLACE = 'marketplace',
  // eslint-disable-next-line no-unused-vars
  MARKETPLACE_LIST = 'marketplace_list',
}

export enum PaymentType {
  // eslint-disable-next-line no-unused-vars
  RARIO_WALLET = 'rario_wallet',
  // eslint-disable-next-line no-unused-vars
  CARD = 'card',
  // eslint-disable-next-line no-unused-vars
}

// export enum WalletType {
//   // eslint-disable-next-line no-unused-vars
//   MARKETING_WALLET = 'marketing_wallet',
// }

export type D3NFTTagStatusType =
  | D3NFTTagStatus.IN_USE
  | D3NFTTagStatus.IN_USE_BY_SELLER
  | D3NFTTagStatus.NOT_IN_USE

export enum D3NFTTagStatus {
  // eslint-disable-next-line no-unused-vars
  IN_USE_BY_SELLER = 'IN_USE_BY_SELLER',
  // eslint-disable-next-line no-unused-vars
  IN_USE = 'IN_USE',
  // eslint-disable-next-line no-unused-vars
  NOT_IN_USE = 'NOT_IN_USE',
}

export type D3NFTTagDataMap = {
  [key: string]: D3NFTTagStatusType
}

export type NFTSaleListingAPIParam = {
  currentPage?: number | string
  limit?: number | string
  sortDir?: number | string
  sortBy?: number | string
}

export interface NFTTokenListings {
  listingId: number
  price: number
  priceCurrency: string
  status: string
  token: {
    id: string
    catalog: {
      playerId: number
    }
    mintTransferedAt: string
    scarcity: string
    serialNumber: number
    tokenId: number
    isOwner?: boolean
    user: {
      username: string
      userId: string
    }
    ownerBlockchainAccount: {
      hashAddr: string
      username: string
    }
    xp?: number
    nftTagStatus?: D3NFTTagStatus
  }
}

export interface NFTSaleListingAPIResponse {
  result: boolean
  data: {
    results: NFTTokenListings[]
    total: number
  }
}

export interface NFTButtonStateProps {
  buy: boolean
  buttonText: string
  disabled: boolean
  showTooltip: boolean
  tooltipText?: string
  onClick?: () => void
}

export interface PreviewPurchaseProps {
  catalogDoc: NFTCatalogDoc
  tokenDoc: NFTTokenDoc
}
export interface CancelListingProps extends PreviewPurchaseProps {
  cancelModalToggle: Function
  onChangeListingStatus: Function
  nftEventData: NftEventDataProps
}

export interface SuccessListingModalProps {
  status?: string
  blockchainTokenId?: string
  isUpdate?: boolean
  nftEventData: NftEventDataProps
}
export interface WhatsNextCardProps {
  image: string
  heading: string
  description: Description
  altText: string
  isReferAndEarnSection?: boolean
}

interface NftEventDataProps {
  card_id?: string
  card_tier?: string
  card_serial?: number
  total_circulation?: string
  card_name?: string
  listing_price?: number
}

export enum PaymentKycStateEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  CHECK_REQUESTED = 'check_requested',
  // eslint-disable-next-line no-unused-vars
  CHECK_CREATED = 'check_created',
  // eslint-disable-next-line no-unused-vars
  SUCCESS = 'success',
  // eslint-disable-next-line no-unused-vars
  FAILED_RETRY = 'failed_retry',
  // eslint-disable-next-line no-unused-vars
  FAILED_BLACK_LISTED = 'failed_black_listed',
  // eslint-disable-next-line no-unused-vars
  NEEDS_REVIEW = 'needs_review',
  // eslint-disable-next-line no-unused-vars
  USER_KYC_LIMIT_EXCEED = 'kyc_attempts_limit_reached',
}

export enum QuickFiltersEnum {
  // eslint-disable-next-line no-unused-vars
  BATTER = 'Batter',
  // eslint-disable-next-line no-unused-vars
  BOWLER = 'Bowler',
  // eslint-disable-next-line no-unused-vars
  ALLROUNDER = 'Allrounder',
  // eslint-disable-next-line no-unused-vars
  WK = 'WK',
}

export enum PreviousPackStatusEnum {
  // eslint-disable-next-line no-unused-vars
  ACTIVE = 'active',
  // eslint-disable-next-line no-unused-vars
  PAUSED = 'paused',
}

export enum VaultCardSourceTagEnum {
  // eslint-disable-next-line no-unused-vars
  WINNING = 'winning',
  // eslint-disable-next-line no-unused-vars
  PACK = 'pack',
  // eslint-disable-next-line no-unused-vars
  MARKETPLACE = 'marketplace',
  // eslint-disable-next-line no-unused-vars
  PICK = 'picks',
  // eslint-disable-next-line no-unused-vars
  AIRDROP = 'airdrop',
  // eslint-disable-next-line no-unused-vars
  BURN_EARN = 'burn_and_earn',
}

export enum CustomArrowsEnum {
  // eslint-disable-next-line no-unused-vars
  PACK_REVEAL = 'pack reveal',
  // eslint-disable-next-line no-unused-vars
  LATEST_CARD_SALE = 'latest card sale',
  // eslint-disable-next-line no-unused-vars
  PICKS = 'picks',
  // eslint-disable-next-line no-unused-vars
  AMBASSADOR = 'ambassador',
  // eslint-disable-next-line no-unused-vars
  TESTIMONIAL = 'testimonial',
}

export interface LatestSaleWidgetProps {
  image: string
  playerName: string
  playerRole: string
  saleDate: string
  price: number
  priceCurrency: string
  teamName: string
  tournament: string
  catalogId: string
  scarcity: string
  buyerUserName: string
}

export interface SeoRouteProps {
  route: string
  title: string
  description: string
  keywords: string
}

export enum ButtonTextEnum {
  // eslint-disable-next-line no-unused-vars
  LOGIN_AND_BUY = 'Login & Buy',
  // eslint-disable-next-line no-unused-vars
  SELECT_AND_BUY = 'Select & Buy',
  // eslint-disable-next-line no-unused-vars
  BUY = 'Buy',
  // eslint-disable-next-line no-unused-vars
  BUY_NOW = 'Buy Now',
  // eslint-disable-next-line no-unused-vars
  PROCESSING = 'Processing',
  // eslint-disable-next-line no-unused-vars
  CANCEL_LISTING = 'Delete Listing',
  // eslint-disable-next-line no-unused-vars
  SELL_ON_MARKET = 'Sell On Market',
  // eslint-disable-next-line no-unused-vars
  NO_LISTINGS_AVAILABLE = 'No Listings available',
  // eslint-disable-next-line no-unused-vars
  COMING_SOON = 'Coming Soon',
  // eslint-disable-next-line no-unused-vars
  PLAY_TRIAL_ON_D3 = 'Play Trial On D3',
}

export enum ThemeEnum {
  // eslint-disable-next-line no-unused-vars
  RARIO = 'RARIO',
  // eslint-disable-next-line no-unused-vars
  CR = 'CR',
  // eslint-disable-next-line no-unused-vars
  D3 = 'D3',
  // eslint-disable-next-line no-unused-vars
  WEBVIEW_APP = 'WEBVIEW_APP',
}

export enum CurrencyEnum {
  // eslint-disable-next-line no-unused-vars
  DEFAULT = 'USD',
  // eslint-disable-next-line no-unused-vars
  IN = '₹',
  // eslint-disable-next-line no-unused-vars
  AU = 'AUD',
  // eslint-disable-next-line no-unused-vars
  USD = 'USD',
}

export interface PackTagsInterface {
  [key: string]: packsThemeType
}

type CircleIconWrapperIcon =
  | 'success'
  | 'pending'
  | 'pending timer'
  | 'fail cross'
  | 'fail exclamation'
  | 'failedWalletAddBalance'
  | 'successWalletAddBalance'
  | 'pendingWalletAddBalance'
  | 'successWalletWithdrawal'
  | 'failedWalletWithdrawal'
  | 'pendingWalletWithdrawal'
  | 'walletTransactionFailed'
  | 'walletTransactionSuccess'
  | 'transactionSuccess'
  | 'transactionPending'
  | 'transactionFailed'
  | 'transactionDebit'

export interface CircleIconWrapperProps {
  iconWidth?: string
  iconHeight?: string
  icon: CircleIconWrapperIcon
  isMarketplace?: boolean
  width?: string
  height?: string
  mobileWidth?: string
  mobileheight?: string
  isTransactionIcon?: boolean
}

export interface PaymentStatusMainHeading {
  [key: string]: string
}

export type kycStatusTextType = {
  statusTextHeading: string
  statusTextSubHeading: string
}

export type contactUsTextType = {
  addressInfoText: string
  supportID: string
  number: string
  address: contactUsAddressType[]
}

type contactUsAddressType = {
  name: string
  streetName: string
  cityName: string
  country: string
}

export enum BrowserNameEnum {
  // eslint-disable-next-line no-unused-vars
  SAFARI = 'safari',
}

export type ReferalItemsProps = {
  timestamp: number
  metadata: ActivityDetailProps
}
export type RefereeActivityProps = {
  createdAt: number
  userProfile: UserProfileProps
  rewardAmount: number
}

export enum FacetLabelEnum {
  // eslint-disable-next-line no-unused-vars
  PLAYERS = 'Players',
  // eslint-disable-next-line no-unused-vars
  NATIONALITY = 'Nationality',
}
export interface WithdrawFundsSummaryProps {
  activeTab: string
  withDrawFee: number
  amountInput: string
  exchangePrice?: number
  totalAmount: string
  bankDetails: BankDetailsResponseData[]
  billingInputs: BillingDetailsInput
  showAccountPreview: boolean
  addBankAccountInputs: WithdrawalOptionsInput
  withdrawableBalance: number
  loader: boolean
  saveBankDetails: (withDrawData: WithdrawalOptionsInput) => Promise<void>
  onSubmit: (tab?: string) => void
  countryInput?: BankBillingDetails | undefined
  selectedBankAccount: BankDetailsResponseData | undefined
  setSelectedBankAccount: (selectedBankAccount: BankDetailsResponseData) => void
}
export interface WithdrawalOptionsProps {
  withdrawAmount: string
  withdrawFee: number
  showWithdrawForm: boolean
  showAccountPreview: boolean
  bankDetails: BankDetailsResponseData[]
  successCallBack: (data: WithdrawalOptionsInput) => void
  loader: boolean
  countryInput: string | undefined
  setFocus?: () => void
  addBankAccountInputs: WithdrawalOptionsInput
  selectedBankAccount: BankDetailsResponseData | undefined
  onChangeTerms: () => void
  isTermAccepted: boolean
  setSelectedBankAccount: (selectedBankAccount: BankDetailsResponseData) => void
}

export enum PaymentCardTypeEnum {
  // eslint-disable-next-line no-unused-vars
  AMEX = 'american express',
  // eslint-disable-next-line no-unused-vars
  DINERS_CLUB = 'diners club',
  // eslint-disable-next-line no-unused-vars
  DISCOVER = 'discover',
  // eslint-disable-next-line no-unused-vars
  JCB = 'jcb',
  // eslint-disable-next-line no-unused-vars
  MAESTRO = 'maestro',
  // eslint-disable-next-line no-unused-vars
  MASTER_CARD = 'mastercard',
  // eslint-disable-next-line no-unused-vars
  VISA = 'visa',
}

export interface WithdrawDetailsProps {
  bankName: string
  accountNumber: string
  swiftCode: string
  amount: number
  serviceFee: number
  totalAmount: number
}

export enum WithdrawalMethodEnum {
  // eslint-disable-next-line no-unused-vars
  PREVIEW = 'preview',
  // eslint-disable-next-line no-unused-vars
  NEW = 'new',
  // eslint-disable-next-line no-unused-vars
  NONE = '',
}

export enum HttpMethod {
  // eslint-disable-next-line no-unused-vars
  GET = 'get',
  // eslint-disable-next-line no-unused-vars
  POST = 'post',
}

export interface SettingsAccountStatusInterface {
  [key: string]: {
    name: string
    content: string
    icon: JSX.Element
  }
}

export enum BankAccountStatusEnum {
  // eslint-disable-next-line no-unused-vars
  PENDING = 'pending',
  // eslint-disable-next-line no-unused-vars
  COMPLETE = 'complete',
  // eslint-disable-next-line no-unused-vars
  FAILED = 'failed',
}

export enum IsoCodes {
  // eslint-disable-next-line no-unused-vars
  INDIA = '+91',
  // eslint-disable-next-line no-unused-vars
  AUSTRALIA = '+61',
  // eslint-disable-next-line no-unused-vars
  UK = '+44',
}

interface PanDetailsInfo {
  value: string
  errorFlag: boolean
  errorMsg: string
}

export interface PanDetails {
  firstName: PanDetailsInfo
  lastName: PanDetailsInfo
  panNumber: PanDetailsInfo
}

export interface PanDetailsApi {
  firstName: string
  lastName: string
  pan: string
  accessToken?: string
  isFrom?: string
}

export interface userLogActivityType {
  auth0Id?: string
  action?: string
}

export interface TabComponentProps {
  activeTab: string
  activatedTabs: Set<string>
  tabValues: string[]
  disableTabs?: boolean
  onClick: (tab: string) => void
  fromModal?: boolean
}

export enum AddFundsSummaryTabList {
  // eslint-disable-next-line no-unused-vars
  FUND_AND_BILLING_DETAILS = 'Fund & Billing Details',
  // eslint-disable-next-line no-unused-vars
  PAYMENT_OPTIONS = 'Payment Options',
}
export enum WithdrawalModalTabList {
  // eslint-disable-next-line no-unused-vars
  BILLING_DETAILS = 'Billing Details',
  // eslint-disable-next-line no-unused-vars
  BANK_ACCOUNT_DETAILS = 'Bank Account Details',
}

export type AddFundsSummaryTab =
  | AddFundsSummaryTabList.FUND_AND_BILLING_DETAILS
  | AddFundsSummaryTabList.PAYMENT_OPTIONS

export type TabsType = {
  label: string
  value: string
  className?: string
}

export type CustomTabsType = {
  label: string
  value: Document
}

export interface NonAlgoliaFacetType {
  label: string
  value: string
}

export type ReceiptSectionType = 'upper' | 'lower' | 'middle'

export enum WithdrawFundsTabList {
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_AND_BILLING_DETAILS = 'Withdrawal & Billing Details',
  // eslint-disable-next-line no-unused-vars
  WITHDRAWAL_OPTIONS = 'Withdrawal Options',
}
export interface WalletBalanceProps {
  closingBalanceUSD: number
  intransitBalanceUSD: number
  marketingWalletBalance: number
  nextFreeWithdrawableDate: number
  withdrawableBalanceUSD: number
  amountToBeExpired: number
  nextExpiringBalance: number
  totalMarketingBalanceCredits: number
  totalMarketingBalanceExpired: number
  totalMarketingBalanceUsed: number
}

export type SortByItem = {
  label: string
  value: string
}

export type appWebViewDataType = {
  deviceID?: string
  appName?: string
  deviceName?: string
  os?: string
  osVersion?: string
  brandName?: string
  appVersion: string
  statusbarHeight?: string
  appsflayerOnInstallConversionData?: object
  appsFlyerUID?: string
}

export type CircleRadioInterface = {
  bgColor: string
  borderColor: string
}

export interface FirstFoldIds {
  ppcFirstFoldId: string
  cricketFandomFirstFoldId: string
  dreamteamFirstFoldId: string
  dreamsquadFirstFoldId: string
  dreamsportsFirstFoldId: string
}

export type AppConfig = {
  USDToINRExchangeRate: number
  campaignData: CampaignData[]
  restrictedRegions: string[]
  USDToGBPExchangeRate: number
  USDToAUDExchangeRate: number
  preferredCurrency: AvailableCurrency | undefined
  leagueSorting: string[]
  leagueSortingCard: string[]
  teamSortingCard?: string[]
  isIndianIP: boolean
  isPracticeMatchRequired?: boolean
  checkoutTransactionFeeRate: number
  inaiTransactionFeeRate: number
  cashfreeTransactionFeeRate: number
  promotionBannerID: string
  cmsPages: CMSPages
  ogPotentialLineUpCMSRefID: string
  referralPageID: string
  burnEarnPageID: string
  isPickEnabled: boolean
  setPreferredCurrency?: (c: AvailableCurrency) => void
  firstFoldCMSIds: FirstFoldIds
  burnEarnCMSRefID: string
  marketplaceFeeRate: number
  disableAddFundsDate: string
  isAddFundsDisabled?: boolean | ''
}

export type NFTTagProps = {
  role?: string
  batting_position?: number
  bowling_position?: number
  tagText?: string | number
  tagBgColor?: string
}

export type PackBoxTagProps = {
  tagText?: string | number
  tagBgColor?: string
}

export const VAULT_DEFAULT_FACETS: FacetsType = [
  { name: 'Tier', attribute: 'attributes.scarcity' },
  { name: 'Leagues & Boards', attribute: 'attributes.league' },
  { name: 'Teams', attribute: 'attributes.team' },
  { name: 'Nationality', attribute: 'attributes.nationality' },
  { name: 'Role', attribute: 'attributes.role' },
  { name: 'Players', attribute: 'attributes.player' },
  { name: 'OG Cards', attribute: 'sub_type' },
  { name: 'Season', attribute: 'attributes.year' },
]

export const DEFAULT_FACETS: FacetsType = [
  { name: 'Tier', attribute: 'attributes.scarcity' },
  { name: 'Leagues & Boards', attribute: 'associated_leagues' },
  { name: 'Teams', attribute: 'associated_teams' },
  { name: 'Nationality', attribute: 'attributes.nationality' },
  { name: 'Role', attribute: 'attributes.role' },
  { name: 'Players', attribute: 'attributes.player' },
  { name: 'OG Cards', attribute: 'sub_type' },
  { name: 'Season', attribute: 'attributes.year' },
]

export enum SideMenuItemsEnum {
  // eslint-disable-next-line no-unused-vars
  Pan_Card = 'Pan Card',
  // eslint-disable-next-line no-unused-vars
  Currency_Preference = 'Preferred Currency',
  // eslint-disable-next-line no-unused-vars
  REFER_EARN = 'Refer & Earn',
}

export enum RightIconTypeEnum {
  // eslint-disable-next-line no-unused-vars
  RIGHT_ARROW = 'data:image/svg+xml,%3Csvg%20width%3D%22(newWidth)%22%20height%3D%22(newHeight)%22%20class%3D%22%22%20viewBox%3D%220%200%2014%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1.16406%206.71387H12.8307M12.8307%206.71387L7.83073%201.71387M12.8307%206.71387L7.83073%2011.7139%22%20stroke%3D%22(newColor)%22%20stroke-width%3D%221.71704%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C/path%3E%3C/svg%3E',
  // eslint-disable-next-line no-unused-vars
  RIGHT_UP_ARROW = 'data:image/svg+xml,%3Csvg%20width%3D%22(newWidth)%22%20height%3D%22(newHeight)%22%20class%3D%22%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M1.83203%2010.1663L10.1654%201.83301M10.1654%201.83301H3.4987M10.1654%201.83301V8.49967%22%20stroke%3D%22(newColor)%22%20stroke-width%3D%221.71704%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3C/path%3E%3C/svg%3E',
}

export type HeadingProps = {
  heading: string
  subHeading?: string
  infoDesc?: string
  infoHeading?: string
  margin?: string
  pageSource?: string
  widgetParentId?: string
}

export type TokenPayloadType = {
  tokenId: string
  status: string
}

export enum UserSpecification {
  // eslint-disable-next-line no-unused-vars
  REFERRAL = 'referral',
  // eslint-disable-next-line no-unused-vars
  INFLUENCER = 'influencer',
}

export interface campaignDataProps {
  discount: {
    amount: number
  }
  campaignType: string
}

export enum LeaguesEnum {
  // eslint-disable-next-line no-unused-vars
  INDIAN_T20_LEAGUE = 'Indian T20 League',
}

export interface ReferralCountProps {
  data: {
    successfulReferralCount: number
  }
}

export enum RegistrationMediumEnum {
  // eslint-disable-next-line no-unused-vars
  IPL_TEAM_PAGE = 'INDT20FB',
}

export enum CategoryLandingUtmSource {
  // eslint-disable-next-line no-unused-vars
  IPL_CATEGORY_PAGE = 'INDT20',
}

export enum IplRefererPageEnum {
  // eslint-disable-next-line no-unused-vars
  INNERCIRCLE = 'innercircle',
}

export interface eventDataProps {
  sub_category: string
  text_url?: string
  category?: string
  amount?: number
  percent?: number
  tab_text?: string
  withdrawal_amount?: number
}

export interface TDSCertificateType {
  id: string
  fy: string
  quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'
}

export interface CardDataInterface {
  image: string
  name: string
  xp: string
  description: string
}

export interface BurnContextProps {
  maxBurnCardLimit: number
  currentCampaign: CampaignData
  setCurrentCampaign: React.Dispatch<React.SetStateAction<CampaignData>>
  scarcityLimit: Map<string, number>
  setScarcityLimit: React.Dispatch<React.SetStateAction<Map<string, number>>>
  selectedCard: Map<string, CardInterface>
  setSelectedCard: React.Dispatch<React.SetStateAction<Map<string, CardInterface>>>
  singleCardDetail: Hit<NFTTokenDoc> | undefined
  setSingleCardDetail: React.Dispatch<React.SetStateAction<Hit<NFTTokenDoc> | undefined>>
  handleBurnCard: (tokenId: string, cardData: CardDataInterface) => void
  openCardDetailModal: (hit: Hit<NFTTokenDoc>) => void
  selectedOption: string
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  cardsBurned: Set<number>
  setCardBurned: React.Dispatch<React.SetStateAction<Set<number>>>
}

export interface VideoModalProps {
  image: string
  video: string
}

export type CardInterface = {
  cardData: CardDataInterface
  tokenId: string
}

export interface SortByProps {
  onSelect?(selectedIndex: string, name: string): void
  items: SortByItem[]
  selectedValue: string
  disableFilter: boolean
}

export type statusTagType = 'info' | 'primary' | 'secondary' | 'disabled'

export interface passwordValidationTypes {
  identical: boolean
  special_char: boolean
  lower_case: boolean
  upper_case: boolean
  numbers: boolean
  pwd_length: boolean
  strength: string
}

export enum PaymentClickActions {
  // eslint-disable-next-line no-unused-vars
  CONTINUE = 'continue',
  // eslint-disable-next-line no-unused-vars
  ADD_FUNDS = 'add_funds',
}

export interface logoutTrackingPayloadTypes {
  sub_category: string
  click_type: string
  click_action: string
  category: string
  view_type?: string
  kyc_step?: string
}
